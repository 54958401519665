import React from "react";

const Home = () => {
  return (
    <div>
      <h1>Welcome to Couch Polyglot!</h1>
      <p>The page is not yet ready but checkout my YouTube channel:</p>

      <a href="https://www.youtube.com/channel/UCHowOK8RrPns_-m1LPfoqEw">
        Couch Polyglot
      </a>
    </div>
  );
};

export default Home;
